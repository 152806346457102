import { render, staticRenderFns } from "./DescriptionBox.vue?vue&type=template&id=688c5c2a&scoped=true&"
import script from "./DescriptionBox.vue?vue&type=script&lang=ts&"
export * from "./DescriptionBox.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./DescriptionBox.vue?vue&type=style&index=0&id=688c5c2a&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "688c5c2a",
  "27b66626"
  
)

export default component.exports
import { Language, Locale } from '../../typings/translations.typings'

export const translations: Locale[Language.EN] = {
  htmlTitle: {
    intranet: 'Intranet (en)',
    index: '',
    about: 'About Us',
    products: 'Our Products',
    news: 'News',
    curiosity: 'What we\'re proud of',
    career: 'Career',
    contacts: 'Contacts',
    contactPoints: 'Company Representation',
    certificates: 'Certificates',
    servicing: 'Servicing',
    reference: 'Reference',
  },
  loginModal: {
    title: 'Log in to Ferrit Electric',
    emailPlaceholder: 'Email',
    passwordPlaceholder: 'Password',
    forgotPasswordAction: 'Forgotten password',
    forgotPasswordInstructions:
      'Please provide your email address. We will send you instuctions to change your password.',
    lastLoginInstructions: 'Last login',
    notYourAccountAction: 'Not you?',
    successForgotPassword:
      'We sent you an email with instructions to change your password.',
  },
  intranet: 'Intranet',
  ferritCareer: 'Work for Ferrit',
  reference: 'Reference',
  servicing: 'Servicing',
  certificates: 'Certificates',
  contactPoints: 'Company representation',
  branches: 'Branches',
  about: 'About Us',
  products: 'Our Products',
  news: 'News',
  contacts: 'Contacts',
  career: 'Career',
  curiosity: 'What we\'re proud of',
  lookingFor: 'Do you want to work on our products?<br> Join us',
  openPositions: 'Open positions',
  playVideo: 'Play video',
  quickSection: 'Quick Section',  
  hrVideoDescription: 'FERRIT HR 2018 long version',
  whatDoWeDo: 'We manufacture industrial electronics',
  ferritServices: '<h2><brand /> services</h2>',
  productReference: 'Product Reference',
  customerReference: 'Customer Reference',
  aboutTheCompany: 'About Us',
  worldOfFerrit: '<h2>World of <brand /></h2>',
  error404: 'Error 404',
  userWidget: {
    user: 'User',
    signout: 'Log out',
  },
  worldMapStack: {
    title: `<span>Our products are used daily in
      <div class="red">Europe, Asia, Africa, North and South America</div> in</span>`,
    description: [
      ['Underground and surface coal mines', 'Ore mines'],
      ['Diamond mines', 'Salt mines.'],
    ],
  },
  page: {
    index: {
      description: `<span></span>`,
      services: [
        {
          icon: 'fax',
          title: 'Development and production',
          description:
            'We develop and manufacture control systems for machines and equipment for our customers. We also supply individual components.',
        },
        {
          icon: 'people-carry',
          title: 'Servicing',
          description:
            'You can leave all the adjustment and setting of technical parameters to us.',
        },
        {
          icon: 'file-signature',
          title: 'Certifications',
          description:
            'We have safety certificates for working in areas of gassy mines with methane or coal dust explosion hazards.',
        },
      ],
      boxCarousel: [
        {
          image: 'index.jpg',
          title: 'Název produktu',
          description:
            '<p>Součástí závěsné jednokolejové dráhy jsou různé manipulační a doplňující prostředky, které usnadňují a urychlují pomocné činnosti.. </p>',
        },
        {
          image: 'ferrit_electric_assembly.jpg',
          title: 'Nadpis2',
          description: '<p>Text2</p>',
        },
      ],
      linkCarousel: [
        [
          {
            href: 'http://cosik.cz/reference1',
            title: 'Název reference 1',
          },
          {
            href: 'http://cosik.cz/reference2',
            title: 'Název reference 2',
          },
          {
            href: 'http://cosik.cz/reference3',
            title: 'Název reference 3',
          },
        ],
        [
          {
            href: 'http://cosik.cz/reference4',
            title: 'Název reference 4',
          },
          {
            href: 'http://cosik.cz/reference5',
            title: 'Název reference 5',
          },
          {
            href: 'http://cosik.cz/reference6',
            title: 'Název reference 6',
          },
        ],
      ],
      aboutTheCompany:
        '<p class="global-line-height">We were founded as a subsidiary of <brand :exclude-electric="true" /> s.r.o., We develop and implement advanced electronic microprocessor solutions for the control and safety of both underground and surface mining machines designed in hazardous environments.</p>',
    },
    about: {
      h2: "<span>Our solutions stand out in:</span>",
      description: `
        <span>
          In <brand/>, we design and manufacture innovative microprocessor-based control and operating systems for safe and automated operation and use 
        </span>
      `,
      advantages: {
        1: '<p>Coherent design for operating in harsh mining environments; our solutions are resistant to moisture, gases, temperature and shock.</p>',
        2: '<p>Leveraging unique and unparalleled knowledge of equipment design for specific mining environments.</p>',
        3: '<p>Superior safety through extensive experience in the development and use of our equipment in mining operations.</p>',
        4: '<p>High mechanical and climatic resistance.</p>',
        5: '<p>Finely tuned: we thoroughly test each of our devices on a testing polygon to verify its correctness and safety prior to deployment.</p>',
        6: '<p>Sharing our know-how in joint development of machines with <brand :exclude-electric="true" />, the leading manufacturer of wheeled mining machines.</p>',
        7: '<p>All of our solutions have been certified for use in mining environments with methane and coal dust explosion hazards.</p>',
      },
    },
    curiosity: {
      description: `<h2 class="red">You know that...</h2>`,
    },
    career: {
      description: `
        <span>
        Contact us (ideally send your CV with your contact details) at <a class="red" href="mailto:kariera@ferrit-electric.cz">kariera@ferrit-electric.cz</a> we will get back to you promptly.

        </span>
      `,
      h2: `<span>Open positions at <brand/></span>`,
    },
    products: {
      description: '<span><brand /> products and services</span>',
      products: {
        left: [
          'Custom development and production of electronic control systems for machinery and equipment',
          'Central machine control units',
          'Display and display units, driver\'s consoles',
          'Accumulator units',
          'Power supplies, power stations, charging stations',
          'Mine pumping station',
          'Sensors, loggers',
        ],
        right: [
          'Lights, horns, beacons',
          'Mining alternators',
          'Shields and barriers',
          'Camera systems for remote machine operation',
          'Radio remote machine control with image transmission',
          'Access and control units and keys',
          'Service and spare parts for mining equipment',
        ],
      },
    },
    certificates: {
      description: `
      <span>
        <h3>In order to meet customer requirements in safe and climatically demanding environments, we certify every product designed for explosion hazardous environments according to the applicable standards with test certification authorities.</h3>
        
        <br/><p/>
        <span class="bold">
          Most of the products of <brand :exclude-electric="true" /> and <brand/> can be used in areas of gaseous mines with hazardous atmospheric conditions 2 according to EN 1127-2 (EN 1127-2) and in areas with methane and coal dust explosion hazards, classified according to § 232 (1) (b) and § 233 (1) (b) of the ČBÚ Decree No. 22/1989 Coll, including mines with a risk of rock and gas bursts and mines classified as hazardous by mine tremors, except for areas with a risk of methane explosions classified pursuant to Article 242(3) of the same Decree. In terms of design, most of <brand :exclude-electric="true" /> and <brand/>'s products are classified in Group I, Category M2 according to Government Regulation No. 116/2016 (Directive 2014/34/EU of the European Parliament and of the Council of the EU). 
        </span>
      </span>`,
    },
    servicing: {
      description: `
        <span>
        <h2>For our customers we provide:</h2>
        <p/>
        <ul>
          <li>Service, i.e. warranty and post-warranty repairs</li>
          <li>Development and customisation of bespoke products</li>
          <li>Technology support</li>
          <li>Customer development of mining-related products</li>
        </ul>
         <p/>
        </span>`,
    },
    reference: {
      description: `<span></span>`,
    },
  },
  icons: {
    lifting: 'Mine lifting and transport machines',
    suspension: 'Mine suspension locomotives',
    lacustrian: 'Mine railway locomotives',
    rails: 'Mining track machines',
    electric: 'Mining technology for machine operators',
  },
  bar: {
    reference: `<div>Our customers determine the direction of our work, we work with them towards a common goal.</div>`,
    servicing: `<div>Customer care doesn't end with the sale.</div>`,
    certificates: `<div>We put safety first in mining operations.</div>`,
    index: `
      <div>
        <brand/> manufactures innovative microprocessor-based control systems for mine hanger and rail locomotives. We also design technology for the operation of wheeled, lifting or transport machines.
      </div>
    `,
    career: `<div><brand/> is looking for skilled, motivated people who like to learn new things. 
      <p/>
      </div>`,

    about: `
      <span>
      <brand /> is a subsidiary of <brand :exclude-electric="true" />, founded to develop and implement advanced electronic microprocessor solutions for the control and safety of both underground and surface mining machines designed for hazardous environments. 
      </span>
    `,
    news: `
      <div>Constant innovation is one of the pillars of our work. We don't innovate only our products, but also our approach to our employees, suppliers and the environment.</div>
    `,
    contacts: `<div><brand/> designs and manufactures innovative microprocessor-based control and operating systems for safe and automated operation and use.</div>`,
    products: `<div>The products supplied by <brand/> are highly technical products for controlling and securing the operation of mining transport and mining machines. Our solutions are developed and produced with particular care. We make high quality products with good service and attractive prices.</div>`,
    positions: `<div></div>`,
    curiosity: `<div>Our world is full of new and exciting technologies and activities.</div>`,
    contactPoints: `<div><brand/> products are offered together with <brand :exclude-electric="true" /> machines on almost all continents.</div>`,
    error404: `<div>Requested page not found.</div>`,
  },
  curiosities: {
    nontechnical: [
      '<p><brand/> employs a unique team of more than 20 developers, electronics engineers and assemblers of electronic components for mining equipment. The team is divided into two departments - the Development Department and the Production Department. A pleasant working environment, family atmosphere and an environment of mutual trust gives us the pleasure of meeting each other while working with the latest technologies and sharing and expanding our knowledge together.</p>',
      '<p>Our electronic devices serve North America, South America, Africa, Asia, Europe and Australia. No matter where they are used, our machines deliver high value through our emphasis on their performance and quality. Our most remote machine in service is in far-away Chile.</p>',
      '<p>The northernmost place where our machines operate is Norilsk in the Russian Federation, located just above the Arctic Circle. In Norilsk, our plants mine metals (mainly nickel) and coal.</p>',
      `<p>Our machines make the dangerous safe. Our machines operate in a mining environment full of water, dust, gas, corrosion and explosive gas. With that in mind, we design our machines to be in accordance with all the safety standards for such environments. We have a designated team for whom ensuring and maintaining safety is the top priority. By responsibly following all safety regulations ensuring the safety of machinery and accessories, we protect the lives and health of miners all around the world. Quality and timely service of our machines is an activity we have grown to depend on. Our customers also depend on us and our service while our machines are in use. Each machine has a diagnostic device which stores data on the machine's operation and compliance with technical working conditions. This way we are able to document the faultless operation of the machine and to demonstrate permanently and openly its quality and contribution, while effectively preventing future breakdowns. The largest machines controlled and secured by our technology are DNK10F mine loaders with the length of 10 metres, width of 2.5 metres and the weight of 30 tons. The DNK10F can load up to 10,000kg in its shovel at a time. The smallest machine which uses our technology is the DMZ50-RC, a mine hanger locomotive with remote control. The locomotive is so compact that the engineer who operates it no longer needs a cabine, but walks beside the machine and operates it by remote control. </p>`,
      '<p>We enjoy our work. Where else can you operate our biggest "English" machine - the DNK 10 mining loader with the weight of 30 tons, which can load 10 tons of material per shovel stroke?</p>',
    ],
    technical: [
      '<p>The area of electronics in which our developers are active is the TOP area. We process the total solution from design, product simulation, PCB design, prototype production and verification of the product in real operation.</p>',
      '<p>We develop our electronic components and devices on modern computer software tools that allow us to virtualize and visualize the designed device, simulate its operation and measure its properties before a single component is switched on. This greatly speeds up development and prevents errors.</p>',
      '<p>There is a fundamental difference between real-time technologies we use in our products and conventional PC technologies. Unlike general-purpose computers such as personal computers, embedded computers are usually single-purpose computers designed for simpler predefined activities and for fast, real-time operation. What matters here is its fast response to input parameters. After all, it is controlling a real machine in a real environment where nothing can get stuck or restart whenever the operation system decides that it is the right time to install software updates.</p>',
      '<p>Our development work is increasingly project-driven. We invest in training and prepare project certifications for our developers. The result is quality and efficient work without unnecessary stress and customer satisfaction with a successful project. We share our knowledge and experience together as a team. If something goes wrong, we always look back and learn from the mistake.</p>',
      '<p>Education is a necessity in our society. The training programme our employees undergo includes English and Russian language, vocational training and soft skills training. In order to keep in touch with customers, our employees are also able to participate in foreign conferences and exhibitions.</p>',
      '<p>We thoroughly test our machines teaming up together with <brand :exclude-electric="true" /> staff on the company\'s polygon. The possibility of using the polygon gives us the ability to thoroughly test each developed component in operation, to see its real physical and technical properties and to verify its quality and functionality. Last but not least, the polygon allows us to play while we work!</p>',
      '<p>We are developing a project to electrify our machines with new Lithium batteries. This is part of our technical and product future, and we want to be a technological leader here as well.</p>',
      '<p>We are working on integrating our machines into an autonomous driving system, where selected machines will work independently, autonomously. We will thus ensure operation where people cannot be or where it is not worthwhile. Autonomous machines, like mining Teslas, are hot news and we are there!</p>',
      '<p>We have joined our parent company <brand :exclude-electric="true" /> in the ‘Improvement and Good Ideas project’, where you can apply your beneficial ideas and earn additional rewards.</p>',
      '<p>Certification of our products for mining operations is one of the most complex activities and starts at the design and development stage. We verify the high safety of our products by certification and comparison with standards.</p>',
      '<p>We print some parts of our products on 3D printers. In the future, we are considering investing in these technologies further for efficient single-piece or small batch production.</p>',
    ],
  },
  contactsInfo: {
    ico: 'ICO ID',
    phone: 'Tel.',
    mobilePhone: 'Mob.',
    fax: 'Fax',
    extract: 'Extract from the <a href="https://or.justice.cz/ias/ui/rejstrik-firma.vysledky?subjektId=345275&typ=PLATNY" class="red">Company Register</a>',
    addressJobSeekers: 'Address for job applicants',
    orders: 'Orders',
    management: 'Company management',
    ceo: 'Chief executive',
    contactPerson: 'Contact person',
  },
  contactPointLocation: {
    czechRepublic: 'Czech Republic',
    slovakia: 'Slovakia',
    poland: 'Poland',
    kazakhstan: 'Kazakhstan',
    ukraine: 'Ukraine',
    bosnia: 'Bosnia and Herzegovina',
    vietnam: 'Vietnam',
    turkey: 'Turkey',
    india: 'India',
    africa: 'Sub-Saharan Africa',
    columbia: 'Columbia',
    china: 'China',
    peruBoliviaEcuador: 'Peru, Bolivia, Ecuador',
  },
  genericLoginError:
    'An error occurred during login. Please try again.',
}

export enum Language {
  CS = 'cs',
  EN = 'en',
  RU = 'ru',
  // ES = 'es',
}

type BoxCarousel = {
  image: string
  title: string
  description: string
}

type LinkCarousel = {
  title: string
  href: string
}

type Icons = {
  suspension: string
  rails: string
  lacustrian: string
  lifting: string
  electric: string
}

type Bar = {
  index: string
  about: string
  career: string
  news: string
  contacts: string
  products: string
  positions: string
  curiosity: string
  certificates: string
  servicing: string
  reference: string
  contactPoints: string
  error404: string
}

export type Advantages = {
  [key in number]: string
}

type FerritService = {
  icon: string
  title: string
  description: string
}

// obsah kazde stranky
type Page = {
  description: string
  services?: Array<FerritService>
  products?: {
    //todo: refactor (pages.products.products)
    left: Array<string>
    right: Array<string>
  }
  h2?: string
  advantages?: Advantages
  boxCarousel?: Array<BoxCarousel>
  linkCarousel?: LinkCarousel[][]
  aboutTheCompany?: string
}

// typove stranky
export enum Pages {
  index = 'index',
  about = 'about',
  career = 'career',
  news = 'news',
  curiosity = 'curiosity',
  openPositions = 'openPositions',
  products = 'products',
  certificates = 'certificates',
  servicing = 'servicing',
  reference = 'reference',
  contacts = 'contacts',
  contactPoints = 'contactPoints',
  intranet = 'intranet',
}

export const PrettyLanguage = {
  [Language.CS]: 'Čeština',
  [Language.EN]: 'English',
  [Language.RU]: 'Русский',
  // [Language.ES]: 'Español'
}

export const LanguageFlags = {
  [Language.CS]: '🇨🇿',
  [Language.EN]: '🇬🇧',
  [Language.RU]: '🇷🇺',
  // [Language.ES]: '🇪🇸'
}

export type Locale = {
  [key in Language]?: {
    htmlTitle: {
      [key in Pages]?: string
    }
    loginModal: {
      title: string
      emailPlaceholder: string
      passwordPlaceholder: string
      forgotPasswordAction: string
      forgotPasswordInstructions: string
      lastLoginInstructions: string
      notYourAccountAction: string
      successForgotPassword: string
    }
    intranet: string
    ferritCareer: string
    reference: string
    servicing: string
    contactPoints: string
    certificates: string
    branches: string
    career: string
    about: string
    products: string
    curiosity: string
    news: string
    contacts: string
    openPositions: string
    lookingFor: string
    playVideo: string
    hrVideoDescription: string
    whatDoWeDo: string
    ferritServices: string
    productReference: string
    customerReference: string
    aboutTheCompany: string
    worldOfFerrit: string
    quickSection: string
    error404: string
    userWidget: {
      user: string
      signout: string
    }
    worldMapStack: {
      title: string
      description: Array<Array<string>>
    }
    icons: Icons
    bar: Bar
    page: {
      [key in Pages]?: Page
    }
    curiosities: {
      technical: Array<string>
      nontechnical: Array<string>
    }
    contactsInfo: {
      ico: string
      phone: string
      mobilePhone: string
      fax: string
      extract: string
      addressJobSeekers: string
      orders: string
      management: string
      ceo: string
      contactPerson: string
    }
    contactPointLocation: {
      czechRepublic: string
      slovakia: string
      poland: string
      kazakhstan: string
      ukraine: string
      bosnia: string
      vietnam: string
      turkey: string
      india: string
      africa: string
      columbia: string
      china: string
      peruBoliviaEcuador: string
    }
    genericLoginError: string
  }
}
